@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&family=Inder&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    @apply font-inder
}

body::-webkit-scrollbar{
  @apply w-0;

}

*:focus{
  outline: none !important;
}

.scroll-v-hide::-webkit-scrollbar{
  @apply h-0 w-0;
}

.map-container{
  width: 100%;
  height: 100vh;
}


.gm-style-cc{
  display: none !important;
}

.gmnoprint > :first-child{
  width: 28px !important; 
  height: 60px !important;
  border-radius: 5px !important;
  overflow: hidden !important;
}

.gm-control-active {
  background-color: white !important; /* définir la couleur de fond du bouton de zoom */
  border-radius: 3px !important; /* ajouter un rayon de bordure */
  border: none !important; /* supprimer la bordure */
  width: 28px !important; /* ajuster la largeur du bouton de zoom */
  height: 28px !important; /* ajuster la hauteur du bouton de zoom */
  box-shadow: none !important; /* supprimer l'ombre sur le bouton */
}

.gm-control-active img{
  width: 40% !important;
  height: 100% !important;
}

.gm-style .gm-zoom-control button:hover {
  background-color: #e0e0e0; /* définir la couleur de fond du bouton de zoom au survol */
}

.gm-style-iw-t{
  bottom: 30px !important;
}

.scrollbar::-webkit-scrollbar{
  @apply h-[4px] w-[2px];

}
.scrollbar::-webkit-scrollbar-track {
  @apply bg-[#F8F8F8] rounded-full;
}
.scrollbar::-webkit-scrollbar-thumb {
  @apply bg-[#cecaca6e] rounded-full;
}

.scrollbar-v::-webkit-scrollbar {
  @apply w-[8px];

}
.scrollbar-v::-webkit-scrollbar-track {
  @apply bg-[#ffffff] rounded-full;
}
.scrollbar-v::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  @apply bg-[#c9c9c9] rounded-full;
}

.scroll-v-hide::-webkit-scrollbar{
  @apply h-0 w-0;
}

.limit-1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden; 
  }
.limit-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; 
  }

.limit-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden; 
  }

.input-radio:checked ~ .form-card .check{
  @apply visible opacity-100
}

.inputRadio:checked ~ .custom-radio .rd-check{
  @apply border-[5px] border-primary-blue-500
}

/* custom form builder */
.form-wrap.form-builder .frmb .form-elements [contenteditable].form-control, .form-wrap.form-builder .frmb .form-elements input[type=text], .form-wrap.form-builder .frmb .form-elements input[type=number], .form-wrap.form-builder .frmb .form-elements input[type=date], .form-wrap.form-builder .frmb .form-elements input[type=color], .form-wrap.form-builder .frmb .form-elements textarea, .form-wrap.form-builder .frmb .form-elements select {

  padding: 6px 12px;
  border: none !important;
  background-color: #fff;
}

.form-wrap.form-builder .frmb .form-elements {
  padding: 10px !important;
  background: #F6F7FB !important;
  border-radius: 3px;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.10) !important;
}

.form-wrap.form-builder .frmb li.form-field {
  padding: 17px !important;
}

.form-wrap.form-builder .frmb li.form-field .close-field {
  position: absolute;
  color: #666;
  left: 50%;
  bottom: 6px;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.10) !important;
  border-left: 1px solid rgba(0, 0, 0, 0.10) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.10) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10) !important;
  border-radius: 3px !important;
  font-size: 14px !important;
}

.form-wrap.form-builder .frmb-control li {
  box-shadow: none !important;
}

.form-wrap.form-builder .stage-wrap.empty {
  border: none !important;
  background-color: #fff !important;
  border-radius: 6px !important;
}

.form-wrap.form-builder .frmb-control li:first-child {
  border-radius: 6px 6px 0 0 !important;
}
.form-wrap.form-builder .frmb-control li:last-child {
  border-radius: 0 0 6px 6px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #000;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover, 
textarea:-webkit-autofill:focus, 
textarea:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #000;
}

.option-id, 
.option-type,
.className-wrap,
.description-wrap,
.access-wrap,
.formbuilder-icon-file{
  display: none !important;
}

.StripeElement {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 6px;
  height: 50px;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  color: black;
}

/* .option-label.option-attr{
  width: 88% !important;
}

.option-value.option-attr{
  display: none !important;
} */

.StripeElement--focus {
  outline: none;
}


/* Custom phoneInput */

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  line-height: 25px;
  height: 50px !important;
  width: 100% !important;
  outline: none;
}

/* spinner loader */
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2.4px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.mapView_page .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom{
  left: 0 !important;
  bottom: 73px !important;
}

.gm-ui-hover-effect{
  height: 30px !important;
}

.gm-ui-hover-effect span{
  height: 16px !important;
  width: 16px !important;
}

.gm-style-iw-d br{
  display: none !important;
}





